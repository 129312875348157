import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { FieldCheckbox } from '../../../components';
import { useState } from 'react';


const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    getShowMapValue,
    showMapValue,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const [showMap,setshowMap] = useState(showMapValue);

  const onChange = (e) => {
    setshowMap (e.target.checked);
    getShowMapValue(showMap);
  }

  return (
    <div className={classes}>
<div>
{isSortByActive ? (
          <div className={css.sortyByWrapper}>
            {/* <span className={css.sortyBy}>
              <FormattedMessage id="MainPanelHeader.sortBy" />
            </span> */}
          
              <FormattedMessage id="MainPanelHeader.sortBy" />
              {sortByComponent}
         
            {children}

            {/* <div className={css.mapControler}>
              Show Map &nbsp;
              <input 
                className={css.inputswitchMap} 
                type="checkbox" 
                hidden="hidden"
                defaultChecked={true}
                id="map" 
                onChange={(e) => onChange(e)}
              />
              <label className={css.switchMap} for="map"></label>
            </div> */}
          </div>
        ) : null}
              {noResultsInfo ? noResultsInfo : null}

</div>

      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}  
              />
            )}
            {/* <span className={css.categorySelectedName}> 
              &nbsp; Bussiness Pro Listings
            </span> */}
          </span>
        </div>

      </div>




    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  showMap: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
